import { createTheme } from '@mui/material/styles';

export const CustomColorScheme = {
  appbar: '#3b3b06',
  body: '#fcba03',

  blue: "#3244a8",
  cyan: '#3df0f2',
  darkBlue: "#0c1069",
  darkBrown: '#999966',
  darkGreen: '#538a63',
  darkGreenBrown: '#3b3b06',
  darkRed: '#a60208',
  darkTan: "#a6a686",
  darkestBrown: '#565603',
  gray: '#777',
  green: "#32a852",
  greenBrown: '#4d4d18',
  lightBlue: '#458cff',
  lightGold: '#f2eb77',
  lightGray: '#999',
  lightTan: '#ebead8',
  lightestTan: '#ffffeb',
  lightYellow: '#f9ff8c',
  mediumBrown: '#c7c396',
  offwhite: '#e0e0d1',
  offWhiteGreen: "#c1e6b8",
  orange: "#fcba03",
  red: "#ff0000",
  rose: '#ff75b1',
  tan: '#d9d9b0',
  white: 'white',
  yellow: '#f0f562',
  transparentWhite25: "rgba(255, 255, 255, 0.25)",
  transparentWhite50: "rgba(255, 255, 255, 0.50)",
  transparentWhite70: "rgba(255, 255, 255, 0.70)",
  transparentOrange70: "rgba(252, 187, 3, 0.70)",
  transparentOrange40: "rgba(252, 187, 3, 0.40)",
  transparentLightTan75: "rgba(235, 234, 216, 0.70)",
};

export const BodyColor = CustomColorScheme['body'];

export const AudiobookTheme = createTheme({
  palette: {
    primary: {
      main: CustomColorScheme['darkGreenBrown'],
      contrastText: CustomColorScheme['white']
    },
    secondary: {
      main: CustomColorScheme['orange'],
    },
  },
});
